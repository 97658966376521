//###########################################################################
// Tables
//###########################################################################

export const tables = {
    elements: {
        tables: null
    },

    init() {
        const self = this

        self.elements.tables = document.querySelectorAll('.body table')

        if (self.elements.tables !== null) {
            // For each table within the .body (CMS controlled), add a wrapping .table-container <div>.
            // This will ensure any overflow is handled by scrolling
            //
            for (let i = 0; i < self.elements.tables.length; i++) {
                let table = self.elements.tables[i];

                self.handleTable(table, i)
            }
        }
    },

    handleTable(table, i) {
        const self = this

        self.addContainerDiv(table, i)
    },

    addContainerDiv(el, id) {
        const div = document.createElement('div');

        let oDiv = div.cloneNode(false)
        oDiv.id = 'table-' + id
        oDiv.classList = 'table-container'
        el.parentNode.insertBefore(oDiv, el)
        oDiv.appendChild(el)
    }
}

document.addEventListener('DOMContentLoaded', () => {
    tables.init()
})


