//###########################################################################
// Tabs
//###########################################################################

import {scrollTo} from "../functions/scrollTo";

export const tabs = {
    config: {
        tab: '.tab',
    },

    elements: {
        tabs: null
    },

    init() {
        const self = this

        self.elements.tabs = document.querySelectorAll(self.config.tab)

        if (self.elements.tabs !== null) {
            for (let i = 0; i < self.elements.tabs.length; i++) {
                let tab = self.elements.tabs[i];

                self.handleTab(tab)
            }
        }
    },

    handleTab(tab) {
        const self = this

        const allTabContents = document.querySelectorAll('.tab__content')
        const allTabImages = document.querySelectorAll('.tab__image')
        const tabContent = document.querySelector(tab.getAttribute('data-content'))
        const tabImage = document.querySelector(tab.getAttribute('data-image'))

        tab.addEventListener('click', function() {
            // Disable all active tabs
            //
            for (let k = 0; k < self.elements.tabs.length; k++) {
                self.elements.tabs[k].classList.remove('is-active')
            }
            for (let i = 0; i < allTabContents.length; i++) {
                allTabContents[i].classList.remove('is-active')
            }
            for (let i = 0; i < allTabImages.length; i++) {
                allTabImages[i].classList.remove('is-active')
            }

            // Enable selected tab
            //
            if (!tab.classList.contains('is-active')) {
                tab.classList.add('is-active')
            }
            if (tabContent !== null && !tabContent.classList.contains('is-active')) {
                tabContent.classList.add('is-active')
            }
            if (tabImage !== null && !tabImage.classList.contains('is-active')) {
                tabImage.classList.add('is-active')
            }

            if (window.innerWidth < 992) {
                // Scroll to tab's content
                const headerHeight = document.querySelector('#header').clientHeight + 40
                scrollTo(tab, -headerHeight)
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    tabs.init()
})


