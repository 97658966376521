import Vue from 'vue'
import lottie from 'lottie-web'
import { throttle } from '../functions/throttle-debounce'

Vue.config.devtools = false

export default new Vue({
	el: '#header',
	data: {
		isOpen: false,
		scrolled: false,
		dropHelp: false,
		dropLoans: false,
		lastScrollPosition: 0,
		hide: false
	},
	methods: {
		toggle: function() {
			this.isOpen = !this.isOpen
			document.body.classList.toggle('fix');
		},
		toggleLink: function() {
			this.isOpen = false;
			document.body.classList.remove('fix');
			this.dropHelp = false;
			this.dropLoans = false;
		},
		helpDropdown: function() {
			this.dropHelp = !this.dropHelp
		},
		loanDropdown: function() {
			this.dropLoans = !this.dropLoans
		},
		handleScroll(event) {
			let header = document.querySelector("#header");

			if (window.scrollY > 10 && !header.classList.contains('.header--scrolled')) {
				this.scrolled = true
			} else if (window.scrollY < 10) {
				this.scrolled = false

				// Clear any height set
				//
				let banner = document.querySelector('#header .banner')
				banner.style.height = banner.scrollHeight + 4 + 'px'
			}

			const currentScrollPosition = window.scrollY || document.documentElement.scrollTop
			if (currentScrollPosition < 0) {
				return
			}
			// Stop executing this function if the difference between
			// current scroll position and last scroll position is less than some offset
			if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 10) {
				return
			}
			this.hide = currentScrollPosition > this.lastScrollPosition
			this.lastScrollPosition = currentScrollPosition
		},

		handleSubMouseEnter() {
			let header = document.querySelector("#header");
			header.classList.add('header--solid')
		},

		handleSubMouseLeave() {
			let header = document.querySelector("#header");
			header.classList.remove('header--solid')
		},

		setBannerHeight() {
			let banner = document.querySelector('#header .banner')

			// Clear any height set
			//
			banner.style.height = ''

			// Set height based on div's height
			//
			banner.style.height = banner.clientHeight + 'px'
		}
	},
	mounted() {
		const self = this

		let header = document.querySelector("#header");

		// Initialise lottie logo animation
		//
		const logo = document.querySelector('#header .logo');

		if (logo !== null) {
			lottie.loadAnimation({
				container: logo, // the dom element that will contain the animation
				renderer: 'svg',
				loop: false,
				autoplay: true,
				path: '/lottie/logo.json'
			});
		}

		// Set banner height
		//
		this.setBannerHeight()
		window.addEventListener('resize', throttle(function() {
			if (!header.classList.contains('.header--scrolled')) {
				self.setBannerHeight()
			}
		}, 500))

		// Handle scroll
		//
		window.addEventListener('scroll', this.handleScroll);

		// Check for initial scroll state and set state accordingly
		//
		if (window.scrollY > 10 && !header.classList.contains('.header--scrolled')) {
			this.scrolled = true
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	}
});
