import Flickity from 'flickity-fade';
require('flickity-imagesloaded');

let quotes = document.querySelector('.quote-slider');
let quotesNext = document.querySelector('.quote-slider--next');
let quotesPrev = document.querySelector('.quote-slider--prev');

if (quotes) {
	let quoteSlider = new Flickity(quotes, {
		pageDots: false,
		prevNextButtons: false,
		wrapAround: true,
		imagesLoaded: true,
		fade: true
	});

	if (quotesNext) {
		quotesNext.addEventListener("click", function(e) {
			e.preventDefault();
			quoteSlider.next();
		});
	}

	if (quotesPrev) {
		quotesPrev.addEventListener("click", function(e) {
			e.preventDefault();
			quoteSlider.previous();
		});
	}
}

let reviewsSlider = document.querySelector('.reviews-slider');
let reviewsNext = document.querySelector('.reviews-slider__control--next');
let reviewsPrev = document.querySelector('.reviews-slider__control--prev');

if (reviewsSlider) {
	let reviewsSliderInstance = new Flickity(reviewsSlider, {
		pageDots: false,
		prevNextButtons: false,
		wrapAround: true,
		imagesLoaded: true,
		fade: true,
		cellSelector: '.review'
	});

	if (reviewsNext) {
		reviewsNext.addEventListener("click", function(e) {
			e.preventDefault();
			reviewsSliderInstance.next();
		});
	}

	if (reviewsPrev) {
		reviewsPrev.addEventListener("click", function(e) {
			e.preventDefault();
			reviewsSliderInstance.previous();
		});
	}
}




