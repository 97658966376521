//###########################################################################
// Section Links
//###########################################################################

import { scrollTo } from '../functions/scrollTo'

export const sectionLinks = {
    elements: {
        sectionLinks: null
    },

    init() {
        const self = this

        self.elements.sectionLinks = document.querySelectorAll('.section-link')

        if (self.elements.sectionLinks !== null) {
            for (let i = 0; i < self.elements.sectionLinks.length; i++) {
                let sectionLink = self.elements.sectionLinks[i];

                self.handleSectionLink(sectionLink)
            }
        }
    },

    handleSectionLink(sectionLink) {
        const self = this

        const targetSection = document.querySelector(sectionLink.getAttribute('href'))

        sectionLink.addEventListener('click', function (e) {
            e.preventDefault()

            const headerHeight = document.querySelector('#header').clientHeight
            scrollTo(targetSection, -headerHeight)
        })
    },
}

document.addEventListener('DOMContentLoaded', () => {
    sectionLinks.init()
})


