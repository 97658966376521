//############################################################################
// Calc data
//############################################################################

export const calcData = {
    defaultLoanTerm: 24,
    loanValue: 2000,
    loanSliderValue: 0,
    prevLoanValue: 0,
    minLoanValue: 500,
    maxLoanValue: 5000,
    loanIncrement: 50,
    buttonLink: "",
    enquiryUrl: "",
    queryStr: "",
    minTerm: 12,
    maxTerm: 36,
    loanTerm: 0,
    prevLoanTerm: 0,
    termSliderValue: 12,
    apr: 0.1,
    monthlyRepaymentAmount: 0,
    totalRepayableAmount: 0,
}