//import Reveal from './functions/scrollReveal';
//import {scrollTo} from './functions/scrollTo';
//import CookieMessage from './components/cookieMessage'
import './components/accord'
import './components/tabs'
import './components/tables'
import './components/sliders'
import './components/calc'
import './components/calcFullWidth'
import './components/navigation'
import './components/sectionLinks'
import './functions/imageHandling'
import './components/copyClipboard'
import lottie from 'lottie-web';
import Cookies from 'js-cookie'

import Sticky from 'sticky-js'
let sticky = new Sticky('.sticky')

let animationSeen = "logo-seen"
let getSeen = Cookies.get(animationSeen);

if (!getSeen) {

	lottie.loadAnimation({
		container: document.querySelector('#a-logo'), // the dom element that will contain the animation
		renderer: 'svg',
		loop: false,
		autoplay: true,
		path: '/lottie/Oakbrook_Loop.json' // the path to the animation json
	});

	Cookies.set(animationSeen, 1);
}

document.querySelector('#cookie-control').addEventListener('click', (e) => {
	e.preventDefault();
	CookieControl.open()
});
